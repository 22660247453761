

const databits = [
  {
    
    title: '¡Gracias por su mensaje!',
    content:
      'En breve un asesor se pondrá en contacto con usted para atender su requerimiento.', 
   
  }
  

];
export default databits;
